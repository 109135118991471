
import { UserGroupIcon, LibraryIcon } from "@heroicons/vue/outline";

export class Insight {
  constructor(insight) {
    this.stats = {
      departments: new MetaInfo(insight.departments?.total, 'departments'),
      units: new MetaInfo(insight.units?.total, 'units'),
      staff: new MetaInfo(insight.staff?.total, 'staff'),
    }
    this.departmentDistribution = insight.staff?.distribution?.departments.map((distribution) => new DistributionInfo(distribution)) || []
    this.unitDistribution = insight.staff?.distribution?.units.map((distribution) => new DistributionInfo(distribution)) || []
  }

  createEmpty() {
    return new Insight({ departments: { total: 0 }, units: { total: 0 }, staff: { total: 0 } });
  }
}

class MetaInfo {
  constructor(count, route) {
    this.name = `Total ${capitalizeFirstLetter(route)}`;
    this.href = `/${route}`;
    this.count = count || 0;
    this.icon = route == 'departments' ? LibraryIcon : UserGroupIcon;
  }
}

class DistributionInfo {
  constructor(distribution) {
    if (distribution.code)
      this.code = distribution.code;
    this.name = distribution.name;
    this.staffCount = distribution.staff_count;
  }
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}