<template>
    <div class="py-8">
  <p class="text-blue-800 text-sm">
    Nigerian Communications Commission, Copyright &#169; 2022, All Right Reserved.
  </p>
</div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppFooter",
  components: {},
  props: [],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
});



</script>

