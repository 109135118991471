<template>
  <template v-for="item in navigation" :key="item.name">
    <div v-if="!item.children" @click="selectItem(item)">
      <router-link
        :to="item.href"
        :class="[
          item.current
            ? 'bg-blue-50 border-blue-900 text-blue-900'
            : 'border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900',
          'group flex items-center px-3 py-2 text-sm font-medium border-l-4',
        ]"
      >
        <component
          :is="item.icon"
          :class="[
            item.current
              ? 'text-gray-500'
              : 'text-gray-400 group-hover:text-gray-500',
            'mr-3 flex-shrink-0 h-6 w-6',
          ]"
          aria-hidden="true"
        />
        {{ item.name }}
      </router-link>
    </div>
    <Disclosure as="div" v-else class="space-y-1" v-slot="{ open }">
      <DisclosureButton
        :class="[
          item.current
            ? 'bg-blue-50 border-blue-900 text-blue-900'
            : 'border-transparent text-gray-600 hover:bg-blue-50 hover:text-gray-900',
          'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium border-l-4',
        ]"
      >
        <component
          :is="item.icon"
          class="
            mr-3
            flex-shrink-0
            h-6
            w-6
            text-gray-400
            group-hover:text-gray-500
          "
          aria-hidden="true"
        />
        <span class="flex-1">
          {{ item.name }}
        </span>
        <svg
          :class="[
            open ? 'text-gray-400 rotate-90' : 'text-gray-300',
            'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150',
          ]"
          viewBox="0 0 20 20"
          aria-hidden="true"
        >
          <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
        </svg>
      </DisclosureButton>
      <DisclosurePanel class="space-y-1">
        <router-link
          :to="subItem.href"
          v-for="subItem in item.children"
          :key="subItem.href"
        >
          <DisclosureButton
            v-if="!subItem.invisible"
            @click="selectItem(subItem)"
            class="
              group
              w-full
              flex
              items-center
              pl-11
              pr-2
              py-2
              text-sm
              font-medium
              text-gray-600
              rounded-md
              hover:text-gray-900 hover:bg-gray-50
            "
          >
            {{ subItem.name }}
          </DisclosureButton>
        </router-link>
      </DisclosurePanel>
    </Disclosure>
  </template>
</template>

<script>
import { computed, defineComponent, ref, watch } from "vue";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";

import {
  AdminNavigation,
  DepartmentHeadNavigation,
  UnitHeadNavigation,
  StaffNavigation,
} from "./routes";
import { useRoute } from "vue-router";
import { Role } from "@/models/user";
import { useStore } from "vuex";

export default defineComponent({
  name: "AppLayoutLinks",
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const navigation = ref(StaffNavigation);

    const role = computed(() => {
      return store.state.auth.user.role.short;
    });

    switch (role.value) {
      case Role.admin.name:
        navigation.value = AdminNavigation;
        break;
      case Role.departmentHead.name:
        navigation.value = DepartmentHeadNavigation;
        break;
      case Role.unitHead.name:
        navigation.value = UnitHeadNavigation;
        break;
      case Role.staff.name:
        navigation.value = StaffNavigation;
        break;
      default:
        navigation.value = StaffNavigation;
        break;
    }

    const selectItem = (menuItem) => {
      if (menuItem.href && menuItem.href.length > 2) {
        navigation.value.map((item) => (item.current = false));
        const menuItemIndex = navigation.value.findIndex(
          (item) =>
            item.href == menuItem.href ||
            item.children?.find((child) => menuItem.href.includes(child.href))
        );
        if (menuItemIndex >= 0) navigation.value[menuItemIndex].current = true;
      }
    };

    selectItem({ href: route.path });

    watch(
      () => route.path,
      async (routePath) => {
        selectItem({ href: routePath });
      }
    );

    return {
      role,
      navigation,
      selectItem,
    };
  },
});
</script>

