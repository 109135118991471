import {
  CalendarIcon,
  ChartBarIcon,
  HomeIcon,
  InboxIcon,
  UsersIcon,
  CogIcon
} from '@heroicons/vue/outline'

const AllNavigation = [
  { name: 'Dashboard', icon: HomeIcon, current: true, href: '/dashboard' },
  {
    name: 'Departments',
    icon: UsersIcon,
    current: false,
    children: [
      { name: 'All Departments', href: '/departments', current: false },
      { name: 'Add Departments', href: '/add-department', current: false },
      { name: 'Department Details', href: '/department', invisible: true, current: false },
    ],
  },
  {
    name: 'Units',
    icon: CalendarIcon,
    current: false,
    children: [
      { name: 'All Units', href: '/units', current: false },
      { name: 'Add Units', href: '/add-unit', current: false },
      { name: 'Unit Details', href: '/unit', invisible: true, current: false },
    ],
  },
  {
    name: 'Staff',
    icon: ChartBarIcon,
    current: false,
    children: [
      { name: 'All Staff', href: '/staff', current: false },
      { name: 'Add Staff', href: '/add-staff', current: false },
      { name: 'Staff Details', href: '/staff', invisible: true, current: false },
    ],
  },
  {
    name: 'Assessments',
    icon: InboxIcon,
    current: false,
    children: [
      { name: 'Self Assessement', href: '/assessment', current: false },
      { name: 'Staff Assessment', href: '/assessment/staff', current: false },
    ],
  },
  { name: 'Settings', icon: CogIcon, current: false, href: '/settings' },
]

const removeChildren = (menuItem, indexes) => {
  const temp = { ...menuItem }
  indexes.forEach(index => {
    delete temp.children[index];
  });
  temp.children.flat()
  return ({ ...temp, children: temp.children.flat() });
}

const AdminNavigation = [AllNavigation[0], removeChildren(AllNavigation[1], []), removeChildren(AllNavigation[2], [1]), removeChildren(AllNavigation[3], [1]), removeChildren(AllNavigation[4], []), AllNavigation[5]];
const DepartmentHeadNavigation = [removeChildren(AllNavigation[2], [1]), removeChildren(AllNavigation[3], [1]), removeChildren(AllNavigation[4], []), AllNavigation[5]]
const UnitHeadNavigation = [removeChildren(AllNavigation[3], [1]), removeChildren(AllNavigation[4], []), AllNavigation[5]]
const StaffNavigation = [removeChildren(AllNavigation[4], [1]), AllNavigation[5]]


export { AdminNavigation, DepartmentHeadNavigation, UnitHeadNavigation, StaffNavigation }