import { httpService } from "@/services/http.service"
import { Pagination } from "@/models/pagination"
import { FileDTO } from "@/models/dtos/file-dto";
import { Unit } from "@/models/unit";

const rootURL = 'units';

const state = {
  list: Pagination.prototype.createEmpty()
}

const actions = {
  create: async ({ dispatch }, data) => {
    const response = await httpService.post(`${rootURL}`, data)
    dispatch("getAll")
    return response.data.message;
  },
  import: async (context, data) => {
    const response = await httpService.post(`${rootURL}/import`, new FileDTO(data))
    return response.data.message;
  },
  getAll: async ({ commit }, options) => {
    const response = await httpService.get(`${rootURL}`, { params: options })
    if (!options || (options && !options.paginate))
      return response.data.data.map((unit) => new Unit(unit))
    commit("SET_UNITS", response.data.data)
  },
  get: async (context, id) => {
    const response = await httpService.get(`${rootURL}/${id}`)
    return new Unit(response.data.data)
  },
  update: async ({ dispatch }, data) => {
    const response = await httpService.patch(`${rootURL}/${data.id}`, data)
    dispatch("getAll")
    return response.data.message
  },
  delete: async ({ dispatch }, id) => {
    const response = await httpService.delete(`${rootURL}/${id}`)
    dispatch("getAll")
    return response.data.message
  }
}

const mutations = {
  SET_UNITS: (state, units) => {
    const unitsPagination = new Pagination(units, "units/getAll")
    unitsPagination.data = unitsPagination.data.map((unit) => new Unit(unit))
    state.list = unitsPagination
  },
  CLEAR: (state) => {
    state.list = Pagination.prototype.createEmpty()
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}