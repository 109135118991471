import AppLayoutDashboard from '@/layouts/AppLayoutDashboard.vue'
import AppLayoutDefault from '@/layouts/AppLayoutDefault.vue'
import { Role } from '@/models/user'

export const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/pages/LoginView.vue'),
    meta: {
      title: 'Login',
      layout: AppLayoutDefault,
    },
  },
  {
    path: "/dashboard",
    name: "DashboardView",
    component: () =>
      import("@/pages/DashboardView.vue"),
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
      allowedRole: Role.admin,
      layout: AppLayoutDashboard,
    },
  },
  {
    path: "/staff",
    name: "staff",
    component: () =>
      import("@/pages/StaffView.vue"),
    meta: {
      title: 'Staff',
      requiresAuth: true,
      allowedRole: Role.unitHead,
      layout: AppLayoutDashboard,
    },
  },
  {
    path: "/add-staff",
    name: "addStaff",
    component: () =>
      import("@/pages/AddStaff.vue"),
    meta: {
      title: 'Add Staff',
      requiresAuth: true,
      allowedRole: Role.admin,
      layout: AppLayoutDashboard,
    },
  },
  {
    path: "/staff/:id",
    name: "StaffDetail",
    component: () =>
      import("@/pages/StaffDetail.vue"),
    meta: {
      requiresAuth: true,
      allowedRole: Role.unitHead,
      layout: AppLayoutDashboard,
    },
  },
  {
    path: "/departments",
    name: "departments",
    component: () =>
      import("@/pages/DepartmentView.vue"),
    meta: {
      title: 'Departments',
      requiresAuth: true,
      allowedRole: Role.admin,
      layout: AppLayoutDashboard,
    },
  },
  {
    path: "/add-department",
    name: "addDepartment",
    component: () =>
      import("@/pages/AddDepartment.vue"),
    meta: {
      title: 'Add Department',
      requiresAuth: true,
      allowedRole: Role.admin,
      layout: AppLayoutDashboard,
    },
  },
  {
    path: "/department/:id",
    name: "DepartmentDetail",
    component: () =>
      import("@/pages/DepartmentDetail.vue"),
    meta: {
      requiresAuth: true,
      allowedRole: Role.admin,
      layout: AppLayoutDashboard,
    },
  },
  {
    path: "/units",
    name: "units",
    component: () =>
      import("@/pages/UnitView.vue"),
    meta: {
      title: 'Units',
      requiresAuth: true,
      allowedRole: Role.departmentHead,
      layout: AppLayoutDashboard,
    },
  },
  {
    path: "/add-unit",
    name: "addUnit",
    component: () =>
      import("@/pages/AddUnit.vue"),
    meta: {
      title: 'Add Units',
      requiresAuth: true,
      allowedRole: Role.admin,
      layout: AppLayoutDashboard,
    },
  },
  {
    path: "/unit/:id",
    name: "UnitDetail",
    component: () =>
      import("@/pages/UnitDetail.vue"),
    meta: {
      requiresAuth: true,
      allowedRole: Role.departmentHead,
      layout: AppLayoutDashboard,
    },
  },
  {
    path: "/assessment/staff",
    name: "StaffAssessmentList",
    component: () =>
      import("@/pages/StaffAssessment.vue"),
    meta: {
      title: 'Unassessed Staff',
      requiresAuth: true,
      allowedRole: Role.unitHead,
      layout: AppLayoutDashboard,
    },
  },
  // {
  //   path: "/report",
  //   name: "AssessmentReport",
  //   component: () =>
  //     import("@/pages/AssessmentReport.vue"),
  //   meta: {
  //     title: 'Assessment Reports',
  //     layout: AppLayoutDefault,
  //   },
  // },
  {
    path: "/assessment/staff/:id",
    name: "StaffAssessment",
    component: () =>
      import("@/pages/Assessment.vue"),
    meta: {
      title: 'Staff Assessment',
      requiresAuth: true,
      allowedRole: Role.unitHead,
      layout: AppLayoutDashboard,
    },
  },
  {
    path: "/assessment",
    name: "Assessment",
    component: () =>
      import("@/pages/Assessment.vue"),
    meta: {
      title: 'Assessment',
      requiresAuth: true,
      allowedRole: Role.staff,
      layout: AppLayoutDashboard,
    },
  },
  {
    path: "/settings",
    name: "UserSettings",
    component: () =>
      import("@/pages/UserSettings.vue"),
    meta: {
      title: 'Settings',
      requiresAuth: true,
      allowedRole: Role.staff,
      layout: AppLayoutDashboard,
    },
  },
  {
    path: '/:pathMatch(.*)*', name: '404',
    component: () =>
      import("@/pages/404View.vue"),
    meta: { title: 'Page Not Found', layout: AppLayoutDefault }
  },
]