import { Department } from "./department";
import { Unit } from "./unit";

export class Competency {
  constructor(competency) {
    this.id = competency.id;
    this.name = competency.name;
    this.type = competency.type;
    this.score = competency.score || 1;
    this.comment = competency.comment || "";
    competency.department ? this.department = new Department(competency.department) : null
    competency.unit ? this.unit = new Unit(competency.unit) : null
    this.createdAt = new Date(competency.created_at);
    this.updatedAt = new Date(competency.updated_at);
  }
}


export class CompetencyRating {
  constructor(competencyRating) {
    this.id = competencyRating.id;
    this.type = competencyRating.type;
    this.name = competencyRating.name;
    if (competencyRating.competency)
      this.competency = new Competency(competencyRating.competency);
    this.createdAt = new Date(competencyRating.created_at);
    this.updatedAt = new Date(competencyRating.updated_at);
  }
}