<template>
  <router-view v-slot="{ Component }">
    <component :is="Component"></component>
  </router-view>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppLayoutDefault",
});
</script>

