import { createStore } from 'vuex'
import auth from './modules/auth';
import staff from './modules/staff';
import units from './modules/units';
import loading from './modules/loading';
import insights from './modules/insights';
import departments from './modules/departments';
import competencies from './modules/competencies';
import staffCompetencies from './modules/staff-competencies';

import { Role } from '@/models/user';

export default createStore({
  state: {
    roles: [Role.admin, Role.departmentHead, Role.unitHead, Role.staff],
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    staff,
    units,
    loading,
    insights,
    departments,
    competencies,
    staffCompetencies,
  }
})
