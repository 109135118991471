import { Competency } from "./competency";
import { Unit } from "./unit";

export class Department {
  constructor(department) {
    this.id = department.id;
    this.name = department.name;
    this.code = department.code;
    this.createdAt = new Date(department.created_at);
    this.updatedAt = new Date(department.updated_at);

    if (department.competencies)
      this.competencies = department.competencies.map((competency) => new Competency(competency))
    if (department.units)
      this.units = department.units.map((unit) => new Unit(unit))
    if (department.number_of_units)
      this.numberOfUnits = department.number_of_units
    if (department.total_staff_strength)
      this.staffStrength = department.total_staff_strength
    if (department.hod)
      this.hod = department.hod
  }

  get departmentName() {
    return this.name || this.code
  }
}