import { Staff } from "@/models/staff"
import authService from "@/services/auth.service"
import { httpService } from "@/services/http.service"

const state = {
  user: authService.getUser(),
}

const actions = {
  login: async ({ dispatch }, data) => {
    const response = await httpService.post('login', data)
    authService.setToken(response.data.data.token)
    await dispatch('verifyUser')
  },
  getOTP: async (context, data) => {
    const response = await httpService.get('login', { params: data })
    return response.data.message
  },
  changePassword: async (context, data) => {
    await httpService.patch('account', data)
  },
  verifyUser: async ({ commit }) => {
    const response = await httpService.get('account', { params: { _silent_: true } })
    authService.setUser(response.data.data)
    commit('SET_USER', response.data.data)
  },
  logout: async ({ commit }) => {
    await httpService.delete('logout');
    authService.removeUser()
    authService.removeToken()
    commit('staff/CLEAR', null, { root: true })
    commit('units/CLEAR', null, { root: true })
    commit('insights/CLEAR', null, { root: true })
    commit('departments/CLEAR', null, { root: true })
    commit('competencies/CLEAR', null, { root: true })
    commit('staffCompetencies/CLEAR', null, { root: true })
    commit('CLEAR')
  },
}

const mutations = {
  SET_USER: (state, user) => {
    state.user = new Staff(user)
  },
  CLEAR: (state) => {
    state.user = null
    state.staffProfile = null
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}