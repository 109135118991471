<template>
  <nav class="flex" aria-label="BreadcrumbComponent">
    <ol role="list" class="bg-white rounded-md shadow px-6 flex space-x-4">
      <li class="flex">
        <div class="flex items-center">
          <router-link
            to="/dashboard"
            class="text-blue-900 hover:text-blue-500"
          >
            <HomeIcon class="flex-shrink-0 h-5 w-5" aria-hidden="true" />
          </router-link>
        </div>
      </li>
      <li v-for="page in pages" :key="page.name" class="flex">
        <div class="flex items-center">
          <svg
            class="flex-shrink-0 w-6 h-full text-gray-200"
            viewBox="0 0 24 44"
            preserveAspectRatio="none"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
          </svg>
          <router-link
            :to="page.href"
            class="ml-4 text-sm font-medium text-blue-900 hover:text-blue-500"
            :aria-current="page.current ? 'page' : undefined"
            >{{ page.name }}
          </router-link>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script>
import { defineComponent } from "vue";
import { HomeIcon } from "@heroicons/vue/solid";
// import { useRoute } from "vue-router";

export default defineComponent({
  name: "BreadcrumbComponent",
  components: { HomeIcon },
  props: {
    pages: {
      type: Array,
      required: true,
    },
  },
  setup() {
    // const route = useRoute();
  },
});
</script>

