import { httpService } from "@/services/http.service"
import { Pagination } from "@/models/pagination"
import { FileDTO } from "@/models/dtos/file-dto";
import { Department } from "@/models/department";

const rootURL = 'departments';

const state = {
  list: Pagination.prototype.createEmpty(),
  all: []
}

const actions = {
  create: async ({ dispatch }, data) => {
    const response = await httpService.post(`${rootURL}`, data)
    dispatch("getAll")
    return response.data.message;
  },
  import: async (context, data) => {
    const response = await httpService.post(`${rootURL}/import`, new FileDTO(data))
    return response.data.message;
  },
  getAll: async ({ commit }, options) => {
    const response = await httpService.get(`${rootURL}`, { params: options })
    if (!options || (options && !options.paginate))
      return response.data.data.map((department) => new Department(department))
    commit("SET_DEPARTMENTS", response.data.data)
  },
  getEverything: async ({ commit }) => {
    const response = await httpService.get(`depts`, { params: { _silent_: true } })
    commit("SET_ALL_DEPARTMENTS", response.data.data)
  },
  get: async (context, id) => {
    const response = await httpService.get(`${rootURL}/${id}`)
    return new Department(response.data.data)
  },
  update: async ({ dispatch }, data) => {
    const response = await httpService.patch(`${rootURL}/${data.departmentID}/units/${data.unitID}`, data)
    dispatch("getAll")
    return response.data.message
  },
  delete: async ({ dispatch }, id) => {
    const response = await httpService.delete(`${rootURL}/${id}`)
    dispatch("getAll")
    return response.data.message
  }
}

const mutations = {
  SET_DEPARTMENTS: (state, departments) => {
    const departmentsPagination = new Pagination(departments, "departments/getAll")
    departmentsPagination.data = departmentsPagination.data.map((department) => new Department(department))
    state.list = departmentsPagination
  },
  SET_ALL_DEPARTMENTS: (state, departments) => {
    state.all = departments.map((department) => new Department(department))
  },
  CLEAR: (state) => {
    state.list = Pagination.prototype.createEmpty()
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}