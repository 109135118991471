import { Competency } from "./competency";
import { Department } from "./department";

export class Unit {
  constructor(unit) {
    this.id = unit.id;
    this.name = unit.name;
    this.noOfCompetencies = unit.no_of_competencies;
    this.totalStaffStrength = unit.total_staff_strength;

    if (unit.competencies)
      this.competencies = unit.competencies.map((competency) => new Competency(competency))
    if (unit.department)
      this.department = new Department(unit.department)
    if (unit.hou)
      this.hou = unit.hou

    this.createdAt = new Date(unit.created_at);
    this.updatedAt = new Date(unit.updated_at);
  }

  get overseeingDepartment() {
    if (this.department)
      return this.department.name || this.department.code;
    return "---"
  }
}