import { httpService } from "@/services/http.service"
import { Pagination } from "@/models/pagination"
import { StaffCompetency } from "@/models/staff-competency";

const rootURL = 'staff-competencies';

const state = {
  list: Pagination.prototype.createEmpty()
}

const actions = {
  create: async ({ dispatch }, data) => {
    const response = await httpService.post(`${rootURL}`, data)
    dispatch("getAll")
    return response.data.message;
  },
  getAll: async ({ commit }, options) => {
    const response = await httpService.get(`${rootURL}`, { params: options })
    if (!options || (options && !options.paginate))
      return Object.values(response.data.data).flat(Infinity).map((staffCompetency) => new StaffCompetency(staffCompetency))
    commit("SET_STAFF_COMPETENCIES", response.data.data)
  },
  delete: async ({ dispatch }, id) => {
    const response = await httpService.delete(`${rootURL}/${id}`)
    dispatch("getAll")
    return response.data.message
  }
}

const mutations = {
  SET_STAFF_COMPETENCIES: (state, competencies) => {
    const competenciesPagination = new Pagination(competencies, "staffCompetencies/getAll")
    competenciesPagination.data = Object.values(competencies).flat(Infinity).map((staffCompetency) => new StaffCompetency(staffCompetency))
    state.list = competenciesPagination
  },
  CLEAR: (state) => {
    state.list = Pagination.prototype.createEmpty()
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}