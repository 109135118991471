import { httpService } from "@/services/http.service"
import { Insight } from "@/models/insight";

const rootURL = 'insight';

const state = {
  data: Insight.prototype.createEmpty()
}

const actions = {
  getAll: async ({ commit }, options) => {
    const response = await httpService.get(`${rootURL}`, { params: options })
    commit("SET_INSIGHT", response.data.data)
  },
}

const mutations = {
  SET_INSIGHT: (state, insight) => {
    state.data = new Insight(insight)
  },
  CLEAR: (state) => {
    state.data = Insight.prototype.createEmpty()
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}