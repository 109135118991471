import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/styles/index.css'
import BreadcrumbComponent from "@/components/BreadcrumbComponent.vue"
import LoadingComponent from "@/components/LoadingComponent.vue"

const app = createApp(App);

app.use(store);
app.use(router);

app.component("BreadcrumbComponent", BreadcrumbComponent);
app.component('LoadingComponent', LoadingComponent);

app.mount('#app')