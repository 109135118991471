import store from "@/store";
import axios from "axios";
import authService from "./auth.service"
import notificationService from "./notification.service";

const config = {
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
}

let activeRequests = 0;

const httpService = axios.create(config);

const authInterceptor = (config) => {
  const token = authService.getToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  if (!config?.params?._silent_) {
    if (activeRequests == 0) {
      store.dispatch('loading/startLoading');
    }

    activeRequests++
  }

  return config
}

httpService.interceptors.request.use(authInterceptor)

httpService.interceptors.response.use(
  (response) => {
    if (!response.config?.params?._silent_) {
      activeRequests--
      if (activeRequests == 0) {
        store.dispatch('loading/stopLoading');
      }
    }
    return response
  },
  (error) => {
    if (!error.config?.params?._silent_) {
      activeRequests--
      if (activeRequests == 0) {
        store.dispatch('loading/stopLoading');
      }
    }
    const code = parseInt(error.response && error.response.status);
    // const messages = Object.values(error.response.data?.errors).map((message) => message[0]);
    const message = error.response.data?.message;

    if (code == 401) {
      store.dispatch('auth/logout')
    }

    if (code && code !== 404) {
      notificationService.error(message)
    }

    if (!code) {
      notificationService.error("Internet disconnected")
    }

    return Promise.reject(error)
  }
)

export { httpService }