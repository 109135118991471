
export class StaffCompetency {
  constructor(staffCompentency) {
    this.staff = staffCompentency.staff;
    this.department = staffCompentency.department;
    this.unit = staffCompentency.unit;
    this.competency = staffCompentency.competency;
    this.score = staffCompentency.score;
    this.createdAt = new Date(staffCompentency.created_at);
  }
}