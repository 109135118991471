export class Pagination {
  constructor(pagination, store_action) {
    this.data = pagination['data'] || [];
    this.from = pagination['from'] || null;
    this.total = pagination['total'] || null;
    this.perPage = pagination['per_page'] || null;
    this.lastPage = pagination['last_page'] || null;
    this.currentPage = pagination['current_page'] || 1;
    this.nextPageUrl = pagination['next_page_url'] || null;
    this.prevPageUrl = pagination['prev_page_url'] || null;
    this.storeAction = store_action || '';
  }

  createEmpty() {
    return new Pagination({ data: [] });
  }
}