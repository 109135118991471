export class CreateCompetencyDTO {
  constructor(competency) {
    this.name = competency.name;
    this.type = competency.type;
    this.unit_id = competency.unitID;
  }
}

export class UpdateCompetencyDTO {
  constructor(competency) {
    this.name = competency.name;
    this.type = competency.type;
    this.department_id = competency.departmentID;
  }
}

export class CreateStaffCompetencyDTO {
  constructor(staffCompetencies) {
    this.staff_id = staffCompetencies.staffID;
    this.type = staffCompetencies.type;
    this.competencies = staffCompetencies.competencies.map((competency) => new CompetencyRatingDTO(competency));
  }
}

export class CompetencyRatingDTO {
  constructor(competencyRating) {
    this.id = competencyRating.id
    this.score = competencyRating.score
    this.comment = competencyRating.comment
  }
}
