export class User {
  constructor(user) {
    this.id = user.id;
    this.email = user.email;
    this.name = user.name;
    this.phone = user.phone || "---";
    this.createdAt = user.created_at;
    this.updatedAt = user.updated_at;
    this.image = "https://media.istockphoto.com/vectors/user-icon-flat-isolated-on-white-background-user-symbol-vector-vector-id1300845620?k=20&m=1300845620&s=612x612&w=0&h=f4XTZDAv7NPuZbG0habSpU0sNgECM0X7nbKzTUta3n8=";
  }

  get firstName() {
    return this.name.split(" ")[0]
  }

  get lastName() {
    return this.name.split(" ")[1]
  }
}


class RoleI {
  constructor(name, weight) {
    this.name = name;
    this.weight = weight;
  }
}

export const Role = Object.freeze({
  admin: new RoleI('Admin', 4),
  departmentHead: new RoleI('HOD', 3),
  unitHead: new RoleI('HOU', 2),
  staff: new RoleI('Staff', 1),
})