<template>
  <component :is="this.$route.meta.layout || layout">
    <slot />
  </component>
</template>

<script>
import { defineComponent, shallowRef } from "vue";
import BaseLayout from "./layouts/AppLayoutBase.vue";

export default defineComponent({
  name: "App",
  data: () => ({
    layout: shallowRef(BaseLayout),
  }),
});
</script>


<style>
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background-color: lightgray;
}

*::-webkit-scrollbar-thumb {
  background-color: #1e3a8a;
}
</style>
