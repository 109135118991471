import { Staff } from "@/models/staff";

const _token = `aktiva::token`
const _user = `aktiva::user`

const setToken = (token) => {
  localStorage.setItem(_token, JSON.stringify(token));
}

const getToken = () => {
  const token = localStorage.getItem(_token);
  if (token) {
    return JSON.parse(token)
  } else {
    return null
  }
}

const removeToken = () => {
  localStorage.removeItem(_token)
}

const setUser = (user) => {
  localStorage.setItem(_user, JSON.stringify(user));
}

const getUser = () => {
  const user = localStorage.getItem(_user);
  if (user) {
    return new Staff(JSON.parse(user))
  } else {
    return null
  }
}

const removeUser = () => {
  localStorage.removeItem(_user)
}

export default { setToken, getToken, removeToken, setUser, getUser, removeUser }