import { httpService } from "@/services/http.service"
import { FileDTO } from "@/models/dtos/file-dto";
import { Staff } from "@/models/staff";
import { Pagination } from "@/models/pagination";

const rootURL = 'staffs';

const state = {
  list: Pagination.prototype.createEmpty()
}

const actions = {
  create: async ({ dispatch }, data) => {
    const response = await httpService.post(`${rootURL}`, data)
    dispatch("getAll")
    return response.data.message;
  },
  import: async (context, data) => {
    const response = await httpService.post(`${rootURL}/import`, new FileDTO(data))
    return response.data.message;
  },
  getAll: async ({ commit }, options) => {
    const response = await httpService.get(`${rootURL}`, { params: options })
    if (!options || (options && !options.paginate))
      return response.data.data.map((staff) => new Staff(staff))
    commit("SET_STAFF", response.data.data)
  },
  get: async (context, id) => {
    const response = await httpService.get(`${rootURL}/${id}`)
    return new Staff(response.data.data)
  },
  update: async ({ dispatch }, data) => {
    const response = await httpService.patch(`${rootURL}/${data.id}`, data)
    dispatch("getAll")
    return response.data.message
  },
  delete: async ({ dispatch }, id) => {
    const response = await httpService.delete(`${rootURL}/${id}`)
    dispatch("getAll")
    return response.data.message
  }
}

const mutations = {
  SET_STAFF: (state, staff) => {
    const staffPagination = new Pagination(staff, "staff/getAll")
    staffPagination.data = staffPagination.data.map((_staff) => new Staff(_staff))
    state.list = staffPagination
  },
  CLEAR: (state) => {
    state.list = Pagination.prototype.createEmpty()
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}