import { Competency, CompetencyRating } from "./competency";
import { Department } from "./department";
import { Unit } from "./unit";
import { Role, User } from "./user";

export class Staff {
  constructor(staff) {
    this.id = staff.id;
    this.sex = staff.sex;
    this.unit = staff.unit;
    this.post = staff.post;
    this.grade = staff.grade;
    this.location = staff.location;
    this.profile = new User(staff.profile);
    this.employmentNo = staff.employment_no;
    this.role = staff.role;

    if (staff.department)
      this.department = new Department(staff.department);
    if (staff.unit)
      this.unit = new Unit(staff.unit);
    this.competencies = staff.competencies;
    this.unitCompetencies = staff.unit_competencies ? Object.values(staff.unit_competencies).flat(Infinity).map((competency) => new Competency(competency)) : [];
    this.departmentCompetencies = staff.department_competencies ? Object.values(staff.department_competencies).flat(Infinity).map((competency) => new Competency(competency)) : [];
    this.createdAt = new Date(staff.created_at);
    this.updatedAt = new Date(staff.updated_at);
  }

  get departmentName() {
    if (this.department)
      return this.department.name || this.department.code
    return "---"
  }

  get unitName() {
    if (this.unit)
      return this.unit.name
    return "---"
  }

  get roleWeight() {
    switch (this.role.short) {
      case Role.admin.name:
        return Role.admin.weight;
      case Role.departmentHead.name:
        return Role.departmentHead.weight;
      case Role.unitHead.name:
        return Role.unitHead.weight;
      case Role.staff.name:
        return Role.staff.weight;
      default:
        return Role.staff.weight;
    }
  }

  get isSelfAssessed() {
    return !!Object.values(this.competencies)
      .flat(Infinity)
      .map((competencyRating) => new CompetencyRating(competencyRating))
      .filter((competencyRating) => competencyRating.type == 'self')
      .length
  }

  get isLineManagerAssessed() {
    return !!Object.values(this.competencies)
      .flat(Infinity)
      .map((competencyRating) => new CompetencyRating(competencyRating))
      .filter((competencyRating) => competencyRating.type == 'line-manager')
      .length
  }
}