import { Role } from '@/models/user'
import notificationService from '@/services/notification.service'
import authService from '../../services/auth.service'

const roleGuard = async (to, from, next) => {
  if (to.matched.some(record => record.meta.allowedRole)) {
    if (authService.getUser().roleWeight >= to.meta.allowedRole.weight) {
      next()
    } else {
      const delay = from.name != 'login' ? 2000 : 10;
      
      if (from.name !== 'login')
        notificationService.warning("You are not authorized at access this page.")

      setTimeout(() => {
        switch (authService.getUser().role.short) {
          case Role.staff.name:
            next({ name: "Assessment" })
            break;
          case Role.unitHead.name:
            next({ name: "staff" })
            break;
          case Role.departmentHead.name:
            next({ name: "units" })
            break;
          default:
            next({ name: "Assessment" })
            break;
        }
      }, delay);
    }
  } else {
    next()
  }
}

export default roleGuard