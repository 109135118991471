import { httpService } from "@/services/http.service"
import { Pagination } from "@/models/pagination"
import { FileDTO } from "@/models/dtos/file-dto";
import { CreateCompetencyDTO, UpdateCompetencyDTO } from "@/models/dtos/competency-dto";
import { Competency } from "@/models/competency";

const rootURL = 'competencies';

const state = {
  list: Pagination.prototype.createEmpty()
}

const actions = {
  create: async ({ dispatch }, data) => {
    const response = await httpService.post(`${rootURL}`, new CreateCompetencyDTO(data))
    dispatch("getAll")
    return response.data.message;
  },
  import: async (context, data) => {
    const response = await httpService.post(`${rootURL}/import`, new FileDTO(data))
    return response.data.message;
  },
  getAll: async ({ commit }, options) => {
    const response = await httpService.get(`${rootURL}`, { params: options })
    console.log();
    if (!options || (options && !options.paginate))
      return Object.values(response.data.data).map((_competency) =>
        _competency.map((competency) => new Competency(competency))
      )
    commit("SET_COMPETENCIES", response.data.data)
  },
  get: async (context, id) => {
    const response = await httpService.get(`${rootURL}/${id}`)
    return new Competency(response.data.data)
  },
  update: async ({ dispatch }, data) => {
    const response = await httpService.patch(`${rootURL}/${data.id}`, new UpdateCompetencyDTO(data))
    dispatch("getAll")
    return response.data.message
  },
  delete: async ({ dispatch }, id) => {
    const response = await httpService.delete(`${rootURL}/${id}`)
    dispatch("getAll")
    return response.data.message
  }
}

const mutations = {
  SET_COMPETENCIES: (state, competencies) => {
    const competenciesPagination = new Pagination(competencies, "competencies/getAll")
    competenciesPagination.result = competenciesPagination.result.map((competency) => new Competency(competency))
    state.list = competenciesPagination
  },
  CLEAR: (state) => {
    state.list = Pagination.prototype.createEmpty()
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}